import { Fragment } from 'react'
import { Link } from '@remix-run/react'
import SectionHead from '~/components/Atoms/SectionHead'
import Spacer from '~/components/Ui/Spacer'
import Grid from '~/components/Ui/Grid'
import Center from '~/components/Ui/Center'
import Inner from '~/components/Ui/Inner'
import Skew from '~/components/Effects/Skew'
import Button from '~/components/Ui/Button'
import InView from '~/components/Effects/InView'
import Project from '~/components/Modules/Projects/Project'

const Projects = ({ attributes: { projects, section_head, link, spacers } }) => (
  <Inner className="Projects">
    <Grid.Sizer size="12/14@md" push="1/14@md">
      <Spacer scale={spacers?.top} vertical />
      <SectionHead title={section_head?.title} description={section_head?.description} />
      <Spacer scale="6 10@md" vertical />
      {projects?.map((project, idx) => (
        <Skew key={project.id}>
          <Fragment>
            <Project
              title={project.title}
              url={project?.url?.relative}
              image={project.image}
              services={project?.services?.data}
            />
            {idx + 1 < projects.length && <Spacer scale="5 10@md" vertical />}
          </Fragment>
        </Skew>
      ))}
      {link && (
        <>
          <Spacer scale="6 10@md" vertical />
          <Center>
            <InView>
              <Button as={Link} to={link?.url}>
                {link?.title}
              </Button>
            </InView>
          </Center>
        </>
      )}
      <Spacer scale={spacers?.bottom} vertical />
    </Grid.Sizer>
  </Inner>
)

Projects.defaultProps = {
  attributes: { projects: [] },
}
Projects.propTypes = {}

export default Projects
