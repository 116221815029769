import { Fragment } from 'react'
import InView from '~/components/Effects/InView'
import Inner from '~/components/Ui/Inner'
import Text from '~/components/Ui/Text'
import Spacer from '~/components/Ui/Spacer'
import UiGrid from '~/components/Ui/Grid'

const Numbers = ({ attributes: { lines, spacers } }) => (
  <div className="Numbers">
    <Inner>
      <Spacer scale={spacers?.top} vertical />
      <UiGrid.Sizer size="12/14@md" push="1/14@md">
        {lines.map(line => (
          <Fragment key={line.text}>
            <Spacer scale="6 10@md" />
            <div className="block">
              <InView>
                <Text as="div" type="text-xl" color="dark">
                  {line.number}
                </Text>
              </InView>
              <Spacer scale="3 0@md" />
              <InView delay={0.2}>
                <Text as="div" type="caption" color="dark-80" nowrap>
                  {line.text}
                </Text>
              </InView>
            </div>
            <Spacer scale="6 10@md" />
            <div className="border" />
          </Fragment>
        ))}
      </UiGrid.Sizer>
      <Spacer scale={spacers?.bottom} vertical />
    </Inner>
  </div>
)

Numbers.defaultProps = {}
Numbers.propTypes = {}

export default Numbers
