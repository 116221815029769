import Marquee from '~/components/Effects/Marquee'

const TextMarquee = ({ children, divider, text, speed }) => {
  const longText = Array.from({ length: 3 }, () => text)
    .join(divider)
    .concat(divider)
    .trim()

  return (
    <div className="TextMarquee">
      <Marquee gradient={false} speed={speed} style={{ overflow: 'hidden' }}>
        {longText}&nbsp;
      </Marquee>
    </div>
  )
}

TextMarquee.defaultProps = {
  speed: 100,
}
TextMarquee.propTypes = {}

export default TextMarquee
