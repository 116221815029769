import { motion } from 'framer-motion'

const InView = ({ as, delay, children }) => {
  const squareVariants = {
    visible: { opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.2, delay } },
    hidden: { opacity: 0, y: 50 },
  }

  const Tag = motion[as]

  return (
    <Tag
      className="InView"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 'some', margin: '0px 0px -15% 0px' }}
      variants={squareVariants}
    >
      {children}
    </Tag>
  )
}

InView.defaultProps = {
  as: 'div',
  delay: 0.2,
}
InView.propTypes = {}

export default InView
