import classNames from 'classnames'
import { Link } from '@remix-run/react'
import Button from '~/components/Ui/Button'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Spacer from '~/components/Ui/Spacer'
import ContactButton from '~/components/Atoms/ContactButton'
import Picture from '~/components/Ui/Picture'
import LetterReveal from '~/components/Effects/LetterReveal'
import Icon from '~/components/Ui/Icon'
import ScrollCircle from '~/components/Atoms/ScrollCircle'

const PageHead = ({ attributes: { headline, caption, image, backlink } }) => {
  const hasImage = image !== false

  return (
    <div className={classNames('PageHead', { hasImage })}>
      <div className="content">
        {backlink && (
          <>
            <Text
              as={Link}
              to={backlink.url}
              className="backlink"
              type="caption"
              color={hasImage ? 'white' : 'dark'}
              colorHover={hasImage ? 'brand' : 'brand'}
            >
              <Icon type="arrow-left" color="inherit" />
              <Spacer scale="2" vertical={false} />
              {backlink.title}
            </Text>

            <Spacer scale="10" vertical />
          </>
        )}

        <h1>
          <LetterReveal
            random={false}
            type="text-xxl"
            color={hasImage ? 'white' : 'dark'}
            className="headline"
            stagger={0.02}
          >
            {headline}
          </LetterReveal>
        </h1>

        <Spacer scale="10" vertical />

        <Grid.Sizer size="3/14@md" className="caption">
          <Text as="div" className="description" type="text-s" color={hasImage ? 'white' : 'dark'}>
            {caption}
          </Text>
        </Grid.Sizer>
      </div>

      {image && (
        <>
          <div className="image-underlay" />
          <div className="image">
            <Picture
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...image}
              size={['mobilePortrait:1x', 'mobilePortraitRetina:2x']}
              cover
              queries={[
                { query: '(min-width: 1600px)', size: 'landscapeXL' },
                { query: '(min-width: 900px)', size: 'landscapeL' },
              ]}
            />
          </div>
        </>
      )}

      <ContactButton />
    </div>
  )
}

PageHead.defaultProps = {}
PageHead.propTypes = {}

export default PageHead
