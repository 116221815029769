import Json from '~/components/Helpers/Json'
import Spacer from '~/components/Ui/Spacer'
import Text from '~/components/Ui/Text'
// import styles from './Debug.module.scss'

const Debug = ({ children, component, layout, attributes }) => {
  return (
    <div className="Debug">
      <div className="info">
        <Text as="div" type="text-xxs">
          Layout: {layout}
        </Text>
        <Text as="div" type="text-xxs">
          Component: {component}
        </Text>
        <Spacer vertical scale="0.2" />
        <Json json={attributes} name="attributes" />
      </div>
      <div className="module">{children}</div>
    </div>
  )
}

Debug.defaultProps = {}
Debug.propTypes = {}

export default Debug
