import Inner from '~/components/Ui/Inner'
import Spacer from '~/components/Ui/Spacer'
import Grid from '~/components/Ui/Grid'

const Wysiwyg = ({ attributes: { content, spacers } }) => (
  <div className="Wysiwyg">
    <Inner>
      <Spacer scale={spacers?.top} vertical />
      <Grid.Sizer size="10/14@md" push="2/14@md">
        <div className="typo" dangerouslySetInnerHTML={{ __html: content }} />
      </Grid.Sizer>
      <Spacer scale={spacers?.bottom} vertical />
    </Inner>
  </div>
)

Wysiwyg.defaultProps = {}
Wysiwyg.propTypes = {}

export default Wysiwyg
