import Unknown from '~/components/Modules/Unknown'
import Intro from '~/components/Modules/Intro'
import BoldText from '~/components/Modules/BoldText'
import Services from '~/components/Modules/Services'
import Jobs from '~/components/Modules/Jobs'
import Projects from '~/components/Modules/Projects'
import PageHead from '~/components/Modules/PageHead'
import Logos from '~/components/Modules/Logos'
import Next from '~/components/Modules/Next'
import Paragraph from '~/components/Modules/Paragraph'
import Grid from '~/components/Modules/Grid'
import TwoColumn from '~/components/Modules/TwoColumn'
import Image from '~/components/Modules/Image'
import Video from '~/components/Modules/Video'
import ContactHead from '~/components/Modules/ContactHead'
import Numbers from '~/components/Modules/Numbers'
import Wysiwyg from '~/components/Modules/Wysiwyg'
import TeamProfile from '~/components/Modules/TeamProfile'
import Button from '~/components/Modules/Button'
import PageHeadBanner from '~/components/Modules/PageHeadBanner'
import Links from '~/components/Modules/Links'

export default {
  Unknown,
  Intro,
  BoldText,
  Services,
  Jobs,
  Projects,
  PageHead,
  Logos,
  Next,
  Paragraph,
  Grid,
  TwoColumn,
  Image,
  Video,
  ContactHead,
  Numbers,
  Wysiwyg,
  TeamProfile,
  Button,
  PageHeadBanner,
  Links
}
