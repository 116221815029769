import { useEffect, useRef, useCallback } from 'react'
import useWindowSize from '~/hooks/use-window-size'
import { useMedia } from '~/context/media'

const Skew = ({ children }) => {
  const size = useWindowSize()
  const ref = useRef()
  const mobile = useMedia('xs')

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }

  useEffect(() => {
    if (size.width !== 0 && ref.current && !mobile) {
      requestAnimationFrame(() => skewScrolling())
    }
  }, [size, ref])

  const skewScrolling = useCallback(() => {
    data.current = window.scrollY
    data.previous += (data.current - data.previous) * data.ease
    data.rounded = Math.round(data.previous * 100) / 100
    const difference = data.current - data.rounded
    const acceleration = difference / size.width
    const velocity = +acceleration
    const skew = velocity > 1 ? 0 : velocity * 3.5
    if (ref?.current) {
      ref.current.style.transform = `skewY(${skew}deg)`
    }
    requestAnimationFrame(() => skewScrolling())
  }, [size])

  return (
    <div className="Skew" ref={ref}>
      {children}
    </div>
  )
}

Skew.defaultProps = {}
Skew.propTypes = {}

export default Skew
