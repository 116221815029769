import { useEffect } from 'react';
import { useLoaderData } from '@remix-run/react';
import { usePage } from '~/context/page';
import { redirect } from '~/server/middleware';
import Modules from '~/components/Wrapper/Modules';
import Error from '~/components/Helpers/Error';
import { getPage } from '~/server/loader';
import css from '~/static/index.css';
import metaData from '~/generated/meta.json';

export const loader = async (props) => {
  const { params } = props;

  await redirect(props);

  const slug = params?.['*'] || '';

  const searchParams = Object.fromEntries(new URL(props?.request.url).searchParams);
  const pageData = await getPage(slug, searchParams);


  if (!pageData?.title) {
    throw new Error('missing page data');
  }

  return pageData;
};

export const meta = ({ data, matches }) => {
  const fallback = matches[0]?.data?.site || {};

  return [
  { tagName: 'link', rel: 'stylesheet', href: css },
  { title: data?.seo?.title || fallback?.seo?.title },
  { name: 'description', content: data?.seo?.description || fallback?.seo?.description },
  { name: 'robots', content: data?.seo?.robots || 'index, follow' },
  { property: 'og:site_name', content: fallback?.settings?.name },
  { property: 'og:url', content: data?.url?.relative },
  { property: 'og:type', content: 'website' },
  { property: 'og:title', content: data?.social?.title || fallback?.social?.title },
  { property: 'og:description', content: data?.social?.description || fallback?.social?.description },
  { property: 'og:image', content: data?.social?.images?.facebook || fallback?.social?.images?.facebook },
  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:site', content: fallback?.social?.twitter },
  { property: 'twitter:title', content: data?.social?.title || fallback?.social?.title },
  { property: 'twitter:description', content: data?.social?.description || fallback?.social?.description },
  { property: 'twitter:image', content: data?.social?.images?.twitter || fallback?.social?.images?.twitter },
  ...metaData];

};

export const links = () => [];

const Slug = () => {
  const data = useLoaderData() || {};
  const { setData } = usePage();

  useEffect(() => {
    setData(data);
  }, [data]);

  return <Modules items={data?.modules || []} />;
};

export default Slug;