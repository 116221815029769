import Marquee from '~/components/Effects/Marquee'
import classNames from 'classnames'

const ElementMarquee = ({ children, className, speed, direction }) => (
  <div className={classNames('ElementMarquee', className)}>
    <Marquee gradient={false} speed={speed} style={{ overflow: 'hidden' }} direction={direction}>
      {children}
    </Marquee>
  </div>
)

ElementMarquee.defaultProps = {
  speed: 100,
  direction: 'left',
}
ElementMarquee.propTypes = {}

export default ElementMarquee
