import { Link } from '@remix-run/react'
import LinkButton from '~/components/Ui/Button'
import Spacer from '~/components/Ui/Spacer'
import Center from '~/components/Ui/Center'
import Inner from '~/components/Ui/Inner'
import InView from '~/components/Effects/InView'

const Button = ({ attributes: { link, spacers } }) => (
  <Inner>
    <Spacer scale={spacers?.top} vertical />
    <Center>
      {link && (
        <InView>
          <LinkButton as={Link} to={link?.url}>
            {link?.title}
          </LinkButton>
        </InView>
      )}
    </Center>
    <Spacer scale={spacers?.bottom} vertical />
  </Inner>
)

Button.defaultProps = {}
Button.propTypes = {}

export default Button
