import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Text from '~/components/Ui/Text'

const SectionHead = ({ title, description }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 1 })

  const borderVariants = {
    visible: {
      width: '100%',
      transition: { type: 'spring', bounce: 0, duration: 1, delay: 0.2 },
    },
    hidden: { width: '0%' },
  }

  const textVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', bounce: 0, duration: 0.5, delay: 0.2 },
    },
    hidden: { y: 25, opacity: 0 },
  }

  return (
    <div className="SectionHead" ref={ref}>
      <Text as="div" className="title" type="caption" color="dark-80">
        <motion.h2 initial="hidden" animate={inView ? 'visible' : 'hidden'} variants={textVariants}>
          {title}
        </motion.h2>
      </Text>
      <Text as="div" className="description" type="text-s" color="dark-80">
        <motion.div
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={textVariants}
        >
          {description}
        </motion.div>
      </Text>
      <motion.div
        className="border"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={borderVariants}
      />
    </div>
  )
}

SectionHead.defaultProps = {
  title: '',
  description: '',
}
SectionHead.propTypes = {}

export default SectionHead
