import Picture from '~/components/Ui/Picture'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Inner from '~/components/Ui/Inner'
import Spacer from '~/components/Ui/Spacer'

const Links = ({ attributes: { links } }) =>
  <Inner className="Links">
    <Spacer scale="10 20@md"/>
    <Grid.Sizer size="10/14@md" push="2/14@md">
      <ul className="">
        {links.map(({ link, image }) =>
          <li key={link?.url}>
            <a href={link?.url} className="link">
              <Picture
                {...image}
                size={'thumbnail'}
              />
              <Text type="text-m" color="dark" as="div">
                {link?.title}
              </Text>
            </a>
          </li>
        )}

      </ul>
    </Grid.Sizer>
    <Spacer scale="10 20@md"/>
  </Inner>

Links.defaultProps = {}
Links.propTypes    = {}

export default Links
