import Text from '~/components/Ui/Text'
import Spacer from '~/components/Ui/Spacer'
import Icon from '~/components/Ui/Icon'

const Item = ({ icon, title, text }) => (
  <div className="Item">
    <div className="icon">
      <Icon type={icon} color="dark" />
    </div>
    <Spacer scale="3" vertical />
    <Text as="h3" type="text-ms" color="dark">
      {title}
    </Text>
    <Spacer scale="2" vertical />
    <Text as="div" type="text-xs" color="dark">
      {text}
    </Text>
  </div>
)

Item.defaultProps = {}
Item.propTypes = {}

export default Item
