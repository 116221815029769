import SectionHead from '~/components/Atoms/SectionHead'
import Inner from '~/components/Ui/Inner'
import Spacer from '~/components/Ui/Spacer'
import UiGrid from '~/components/Ui/Grid'
import InView from '~/components/Effects/InView'
import Text from '~/components/Ui/Text'
import Item from '~/components/Modules/Grid/Item'

const gridItemSize = colCount => {
  switch (colCount) {
    case '3':
      return { size: '3/12@md', pull: '1/14@md' }
    case '4':
      return { size: '3/12@md', pull: '' }
    case '6':
      return { size: '2/12@md', pull: '' }
    default:
      return ''
  }
}

const Grid = ({ attributes: { section_head, title, columns, items, spacers } }) => {
  const itemSize = gridItemSize(columns)
  return (
    <div className="Grid">
      <Inner>
        <Spacer scale={spacers?.top} vertical />
        <UiGrid.Sizer size="12/14@md" push="1/14@md">
          <SectionHead title={section_head?.title} description={section_head?.description} />
          <Spacer scale="6 10@md" vertical />
          {title && (
            <>
              <InView>
                <Text type="text-l" color="dark">
                  {title}
                </Text>
              </InView>
              <Spacer scale="6 10@md" vertical />
            </>
          )}
          <UiGrid.Row>
            {items.map((item, idx) => (
              <UiGrid.Column key={item.title + idx} size={itemSize.size} pull={itemSize.pull}>
                <InView delay={idx * 0.05}>
                  <Item {...item} />
                </InView>
                <Spacer scale="20" vertical />
              </UiGrid.Column>
            ))}
          </UiGrid.Row>
        </UiGrid.Sizer>
        <Spacer scale={spacers?.bottom} vertical />
      </Inner>
    </div>
  )
}

Grid.defaultProps = {}
Grid.propTypes = {}

export default Grid
