import { useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useNavigate } from '@remix-run/react'
import useWindowSize from '~/hooks/use-window-size'
import Button from '~/components/Ui/Button'
import { useMedia } from '~/context/media'
import { useSite } from '~/context/site'
import tracker from '~/services/tracker'

const ContactButton = () => {
  const desktop = useMedia('md')
  const { contact } = useSite('settings')
  const size = useWindowSize()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [desktop ? 0.95 : 0.85, desktop ? 1 : 0.9], [1, 0])

  const scaleFactor = desktop ? 34 : 20
  const scaleFrom = 0.9
  const scaleTo = size.width > size.height ? size.width / scaleFactor : size.height / scaleFactor

  const variants = {
    hidden: { scale: scaleFrom },
    visible: { scale: scaleTo },
  }
  const onComplete = v => {
    if (v === 'visible') {
      navigate(contact?.link?.url)
    }
  }

  const onClick = () => {
    setShow(true)
    tracker.event('lead', 'click.contact', 'button')
  }

  return (
    <>
      <motion.div
        className="ContactButton-Overlay"
        variants={variants}
        initial="hidden"
        animate={show ? 'visible' : 'hidden'}
        transition={{ type: 'spring', bounce: 0.1, duration: 1 }}
        onAnimationComplete={onComplete}
        style={{ opacity }}
      />
      <motion.button
        type="button"
        onClick={onClick}
        className="ContactButton"
        style={{ opacity }}
        aria-label="Contact"
      >
        <Button as="div" icon="mail-outlines" type={desktop ? 'dark-80' : 'dark'} />
      </motion.button>
    </>
  )
}

ContactButton.defaultProps = {}
ContactButton.propTypes = {}

export default ContactButton
