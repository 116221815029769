import { useEffect, Fragment } from 'react'
import { useDebug } from '~/context/debug'
import Debug from '../Debug'
import Components from '~/components/Modules'

const Module = props => {
  const debug = useDebug()
  const ModuleComponent = Components[props.component] || Components.Unknown

  return (
    <Fragment>
      {debug?.components && (
        <Debug {...props}>
          <ModuleComponent {...props} />
        </Debug>
      )}
      {!debug?.components && <ModuleComponent {...props} />}
    </Fragment>
  )
}

Module.defaultProps = {}
Module.propTypes = {}

export default Module
