import { NavLink } from '@remix-run/react'
import Center from '~/components/Ui/Center'
import Text from '~/components/Ui/Text'
import InView from '~/components/Effects/InView'
import { useSiteState } from '~/context/state'

const Next = ({ attributes: { caption, link } }) => {
  const { setCursor } = useSiteState()

  return (
    <Center as="div" className="Next">
      <NavLink
        to={link?.url}
        onMouseEnter={() => setCursor('link')}
        onMouseLeave={() => setCursor()}
      >
        <InView>
          <Text as="div" type="caption" color="brand">
            {caption}
          </Text>
        </InView>
        <InView>
          <Text as="div" type="text-xxl" color="white" html>
            {link?.title.replaceAll('|', '<br />')}
          </Text>
        </InView>
      </NavLink>
    </Center>
  )
}

Next.defaultProps = {}
Next.propTypes = {}

export default Next
