import { useRef, useLayoutEffect, useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Image = ({ children }) => {
  const ref = useRef()
  const [refInView, inView, entry] = useInView()
  const [yStart, setYStart] = useState(0)
  const [yEnd, setYEnd] = useState(0)

  const { scrollY } = useScroll()

  const yRange = useTransform(scrollY, [yStart, yEnd], [50, -50])
  const xRange = useTransform(scrollY, [yStart, yEnd], [10, -10])
  const scaleRange = useTransform(scrollY, [yStart, yEnd], [1.2, 1.4])

  useLayoutEffect(() => {
    if (inView) {
      const refBounds = ref.current.getBoundingClientRect()

      const start = refBounds.top + scrollY.get() - window.innerHeight
      setYStart(start)
      setYEnd(start + window.innerHeight + refBounds.height)
    }
  }, [inView, scrollY])

  return (
    <div className="" ref={refInView}>
      <motion.div
        ref={ref}
        style={{ y: yRange, x: xRange, width: '100%', height: '100%', scale: scaleRange }}
      >
        {children}
      </motion.div>
    </div>
  )
}

Image.defaultProps = {}
Image.propTypes = {}

export default Image
