import ClientOnly from '~/components/Helpers/ClientOnly'
import Reveal from '~/components/Effects/Reveal'
import Grid from '~/components/Ui/Grid'
import Picture from '~/components/Ui/Picture'
import Spacer from '~/components/Ui/Spacer'
import Skew from '~/components/Effects/Skew'

const Image = ({ attributes: { width, image, spacers } }) => {
  const isWide = width === 'wide'

  return (
    <Grid.Sizer size={isWide ? '' : '12/14@md'} push={isWide ? '' : '1/14@md'} className="Image">
      <Spacer scale={spacers?.top} vertical />
      <ClientOnly>
        <Reveal width="14vw" color="var(--color-background)" />
      </ClientOnly>
      <Skew>
        <Picture
          {...image}
          size={['mobileLandscape:1x', 'mobileLandscapeRetina:2x']}
          queries={[
            { query: '(min-width: 1600px)', size: 'landscapeXL' },
            { query: '(min-width: 900px)', size: 'landscapeL' },
          ]}
        />
      </Skew>
      <Spacer scale={spacers?.bottom} vertical />
    </Grid.Sizer>
  )
}

Image.defaultProps = {}
Image.propTypes = {}

export default Image
