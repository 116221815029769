import Inner from '~/components/Ui/Inner'
import Spacer from '~/components/Ui/Spacer'
import Grid from '~/components/Ui/Grid'
import InView from '~/components/Effects/InView'
import Text from '~/components/Ui/Text'

const Paragraph = ({ attributes: { title, text, spacers } }) => (
  <Inner className="Paragraph">
    <Grid.Sizer size="12/14@md" push="1/14@md">
      <Spacer scale={spacers?.top} vertical />
      <InView>
        <Text as="div" type="caption" color="dark-80">
          {title}
        </Text>
      </InView>
      <Spacer scale="3 6@md" vertical />
      <InView>
        <Text as="div" type="text-l" color="dark">
          {text}
        </Text>
      </InView>
      <Spacer scale={spacers?.bottom} vertical />
    </Grid.Sizer>
  </Inner>
)

Paragraph.defaultProps = {}
Paragraph.propTypes = {}

export default Paragraph
