import Picture from '~/components/Ui/Picture'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Icon from '~/components/Ui/Icon'
import Spacer from '~/components/Ui/Spacer'
import { useMedia } from '~/context/media'

const PageHeadBanner = ({ attributes: { banner, avatar, title, subtitle, caption, links } }) => {
  const isDesktop = useMedia('md')

  return (<div className="PageHeadBanner">
  <div className="header">
    <Picture
      {...banner}
      size={['mobileLandscape:1x', 'mobileLandscapeRetina:2x']}
      queries={[
        { query: '(min-width: 1600px)', size: 'narrowXL' },
        { query: '(min-width: 900px)', size: 'narrowL' }
      ]}
    />

    <Text className="title" type={"logo-xl"} color={"white"}>
      {title}
    </Text>

    <Grid.Sizer push="2/14@md" className="avatar">
      <div className="avatar-inner">
        <Picture
          {...avatar}
          size={'thumbnail'}
        />
      </div>
    </Grid.Sizer>
  </div>

  <Spacer scale="20 30@md"/>

  <Grid.Sizer size="10/14@md" push="2/14@md" className="info">
    <Grid.Row>
      <Grid.Column size="6/12@md">
        <Text type={"text-m1"} color={"dark"} as={"div"}>
          {subtitle}
        </Text>


        <Text type={"text-s"} color={"dark-50"} as={"div"}>
          {caption}
        </Text>
      </Grid.Column>

      <Grid.Column size="6/12@md" className={"links--holder"}>
        <div className="links">
          {links.map(({ link, icon }) =>
            <div className={"link"} key={link.url}>
              <Icon type={icon} color={isDesktop ? "dark" : 'brand'}/>
              <Text type={"text-s"} color={isDesktop ? "dark" : 'brand'} as={"a"} href={link.url} target={"_blank"} html>
                {link.title}
              </Text>
            </div>
          )}
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid.Sizer>

  <Spacer scale="5 10@md"/>

</div>)
}

PageHeadBanner.defaultProps =
  {}
PageHeadBanner.propTypes    =
  {}

export default PageHeadBanner
