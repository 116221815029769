import ClientOnly from '~/components/Helpers/ClientOnly'
import Reveal from '~/components/Effects/Reveal'
import Grid from '~/components/Ui/Grid'
import Spacer from '~/components/Ui/Spacer'

const Video = ({ attributes: { vimeo_id: vimeoId, width, spacers } }) => {
  const isWide = width === 'wide'

  const q = new URLSearchParams({
    h: '71de1c27b4',
    badge: 0,
    autopause: 0,
    player_id: 0,
    app_id: 58479,
    autoplay: 1,
    loop: 1,
    muted: 1,
    controls: 0,
  }).toString()

  const url = `https://player.vimeo.com/video/${vimeoId}?${q}`

  return (
    <div className="Video-Wrap">
      <Grid.Sizer size={isWide ? '' : '12/14@md'} push={isWide ? '' : '1/14@md'}>
        <Spacer scale={spacers?.top} vertical />
        <div className="Video">
          <div className="cursor-helper" />
          <ClientOnly>
            <Reveal width="14vw" color="var(--color-background)" />
          </ClientOnly>
          <iframe src={url} frameBorder="0" allow="autoplay" allowFullScreen className="Frame" />
          <Spacer scale={spacers?.bottom} vertical />
          {/* <script src="https://player.vimeo.com/api/player.js" /> */}
        </div>
      </Grid.Sizer>
    </div>
  )
}

Video.defaultProps = {}
Video.propTypes = {}

export default Video
