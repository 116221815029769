import { Fragment, useEffect, useLayoutEffect, useState, useRef } from 'react'

const Marquee = ({
  style,
  className,
  play,
  pauseOnHover,
  pauseOnClick,
  direction,
  speed,
  delay,
  loop,
  children,
}) => {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0)
  const [marqueeWidth, setMarqueeWidth] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const containerRef = useRef()
  const marqueeRef = useRef()

  const calculateWidth = () => {
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width)
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width)
    }
  }

  useLayoutEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      calculateWidth()
      window.addEventListener('resize', calculateWidth)
      return () => {
        window.removeEventListener('resize', calculateWidth)
      }
    }
  }, [isMounted])

  useEffect(() => {
    if (marqueeWidth < containerWidth) {
      setDuration(containerWidth / speed)
    } else {
      setDuration(marqueeWidth / speed)
    }
  }, [containerWidth, marqueeWidth])

  return (
    <Fragment>
      {!isMounted ? null : (
        <div ref={containerRef} className={className + ' Marquee marquee-container'}>
          <div
            ref={marqueeRef}
            style={{
              '--play': play ? 'running' : 'paused',
              '--direction': direction === 'left' ? 'normal' : 'reverse',
              '--duration': `${duration}s`,
              '--delay': `${delay}s`,
              '--iteration-count': !!loop ? `${loop}` : 'infinite',
            }}
            className="marquee"
          >
            {children}
          </div>
          <div
            style={{
              '--play': play ? 'running' : 'paused',
              '--direction': direction === 'left' ? 'normal' : 'reverse',
              '--duration': `${duration}s`,
              '--delay': `${delay}s`,
              '--iteration-count': !!loop ? `${loop}` : 'infinite',
            }}
            className="marquee"
          >
            {children}
          </div>
        </div>
      )}
    </Fragment>
  )
}

Marquee.defaultProps = {
  style: {},
  className: '',
  play: true,
  pauseOnHover: false,
  pauseOnClick: false,
  direction: 'left',
  speed: 20,
  delay: 0,
  loop: 0,
  children: null,
}
Marquee.propTypes = {}

export default Marquee
