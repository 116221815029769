// import PropTypes from 'prop-types'
import Module from './Module'
// import styles from './Modules.module.scss'

const Modules = ({ items }) => (
  <div className="">
    {items?.map((props, idx) => (
      <Module key={idx} {...props} />
    ))}
  </div>
)

Modules.defaultProps = {}
Modules.propTypes = {}

export default Modules
