import { motion } from 'framer-motion'

const Reveal = ({ width, color }) => {
  const variants = {
    hidden: v => ({
      x: v,
      transition: { duration: 0.5 },
    }),
    visible: {
      x: 0,
      transition: { duration: 0.5 },
    },
  }

  return (
    <>
      <motion.div
        className="Reveal-Left"
        initial="visible"
        variants={variants}
        whileInView="hidden"
        viewport={{ once: true, amount: 'some', margin: '0px 0px -10% 0px' }}
        custom="-100%"
        style={{ width, background: color }}
      />
      <motion.div
        className="Reveal-Right"
        initial="visible"
        variants={variants}
        whileInView="hidden"
        viewport={{ once: true, amount: 'some', margin: '0px 0px -10% 0px' }}
        custom="100%"
        style={{ width, background: color }}
      />
    </>
  )
}

Reveal.defaultProps = {
  width: '50px',
  color: 'red',
}
Reveal.propTypes = {}

export default Reveal
