import { Link } from '@remix-run/react'
import ClientOnly from '~/components/Helpers/ClientOnly'
import Text from '~/components/Ui/Text'
import Picture from '~/components/Ui/Picture'
import TextMarquee from '~/components/Effects/TextMarquee'
import Image from '~/components/Modules/Projects/Project/Image'
import Reveal from '~/components/Effects/Reveal'
import { useSiteState } from '~/context/state'

const Project = ({ title, url, image, services }) => {
  const { setCursor } = useSiteState()

  return (
    <Link
      to={url}
      className="Project"
      onMouseEnter={() => setCursor('project')}
      onMouseLeave={() => setCursor()}
    >
      <ClientOnly>
        <Reveal width="14vw" color="var(--color-background)" />
      </ClientOnly>

      <div className="image">
        <ClientOnly>
          <Image>
            <Picture
              {...image}
              size={['mobilePortrait:1x', 'mobilePortraitRetina:2x']}
              queries={[
                { query: '(min-width: 1600px)', size: 'landscapeXL' },
                { query: '(min-width: 900px)', size: 'landscapeL' },
              ]}
            />
          </Image>
        </ClientOnly>
      </div>

      <ClientOnly>
        <Text as="div" color="white" type="text-xxl" className="title">
          <TextMarquee divider=" * " text={title} />
        </Text>
      </ClientOnly>

      <ul className="tags">
        {services.map(service => (
          <li key={service.title}>
            <Text as="span" className="tag" type="text-xs" color="white">
              {service.title}
            </Text>
          </li>
        ))}
      </ul>
    </Link>
  )
}

Project.defaultProps = {
  title: '',
  image: false,
  services: [],
}
Project.propTypes = {}

export default Project
