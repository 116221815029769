import { Link } from '@remix-run/react'
import SectionHead from '~/components/Atoms/SectionHead'
import Spacer from '~/components/Ui/Spacer'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Icon from '~/components/Ui/Icon'
import InView from '~/components/Effects/InView'
import TextHover from '~/components/Effects/TextHover'
import Inner from '~/components/Ui/Inner'
import { useMedia } from '~/context/media'

const Services = ({ attributes: { services, section_head, spacers } }) => {
  const mobile = useMedia('xs')

  return (
    <Inner className="Services">
      <Grid.Sizer size="12/14@md" push="1/14@md">
        <Spacer scale={spacers?.top} vertical />
        <SectionHead title={section_head?.title} description={section_head?.description} />
        <Spacer scale="6 10@md" vertical />
        <ul>
          {services.map(service => (
            <li key={service.id}>
              <InView as="h3">
                <Text
                  as={Link}
                  to={service.url.relative}
                  className="link"
                  type="text-xl"
                  color={mobile ? 'brand' : 'dark'}
                >
                  <TextHover
                    from={{
                      fontWeight: 600,
                      color: 'var(--color-textDark)',
                      transition: { color: { duration: 0.05 } },
                    }}
                    to={{
                      fontWeight: 700,
                      color: 'var(--color-textBrand)',
                      transition: { color: { duration: 0.05 } },
                    }}
                  >
                    <span>{service.title}</span>
                    <Icon type="arrow-right" color="inherit" />
                  </TextHover>
                </Text>

                {mobile && <Spacer scale="8" vertical />}
              </InView>
            </li>
          ))}
        </ul>
        <Spacer scale={spacers?.bottom} vertical />
      </Grid.Sizer>
    </Inner>
  )
}

Services.defaultProps = {
  attributes: { services: [] },
}
Services.propTypes = {}

export default Services
