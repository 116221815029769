[
  {
    "rel": "shortcut icon",
    "href": "/favicons/favicon.ico",
    "tagName": "link"
  },
  {
    "rel": "icon",
    "type": "image/png",
    "sizes": "16x16",
    "href": "/favicons/favicon-16x16.png",
    "tagName": "link"
  },
  {
    "rel": "icon",
    "type": "image/png",
    "sizes": "32x32",
    "href": "/favicons/favicon-32x32.png",
    "tagName": "link"
  },
  {
    "rel": "icon",
    "type": "image/png",
    "sizes": "48x48",
    "href": "/favicons/favicon-48x48.png",
    "tagName": "link"
  },
  {
    "rel": "manifest",
    "href": "/favicons/manifest.json",
    "tagName": "link"
  },
  {
    "name": "mobile-web-app-capable",
    "content": "yes"
  },
  {
    "name": "theme-color",
    "content": "#fff"
  },
  {
    "name": "application-name"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "57x57",
    "href": "/favicons/apple-touch-icon-57x57.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "60x60",
    "href": "/favicons/apple-touch-icon-60x60.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "72x72",
    "href": "/favicons/apple-touch-icon-72x72.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "76x76",
    "href": "/favicons/apple-touch-icon-76x76.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "114x114",
    "href": "/favicons/apple-touch-icon-114x114.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "120x120",
    "href": "/favicons/apple-touch-icon-120x120.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "144x144",
    "href": "/favicons/apple-touch-icon-144x144.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "152x152",
    "href": "/favicons/apple-touch-icon-152x152.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "167x167",
    "href": "/favicons/apple-touch-icon-167x167.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "180x180",
    "href": "/favicons/apple-touch-icon-180x180.png",
    "tagName": "link"
  },
  {
    "rel": "apple-touch-icon",
    "sizes": "1024x1024",
    "href": "/favicons/apple-touch-icon-1024x1024.png",
    "tagName": "link"
  },
  {
    "name": "apple-mobile-web-app-capable",
    "content": "yes"
  },
  {
    "name": "apple-mobile-web-app-status-bar-style",
    "content": "black-translucent"
  },
  {
    "name": "apple-mobile-web-app-title"
  },
  {
    "rel": "icon",
    "type": "image/png",
    "sizes": "228x228",
    "href": "/favicons/coast-228x228.png",
    "tagName": "link"
  },
  {
    "name": "msapplication-TileColor",
    "content": "#fff"
  },
  {
    "name": "msapplication-TileImage",
    "content": "/favicons/mstile-144x144.png"
  },
  {
    "name": "msapplication-config",
    "content": "/favicons/browserconfig.xml"
  },
  {
    "rel": "yandex-tableau-widget",
    "href": "/favicons/yandex-browser-manifest.json",
    "tagName": "link"
  }
]
