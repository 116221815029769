import { Suspense, lazy } from 'react'
import Text from '~/components/Ui/Text'
import Parallax from '~/components/Effects/Parallax'
import ClientOnly from '~/components/Helpers/ClientOnly'
import ContactButton from '~/components/Atoms/ContactButton'
import { useMedia } from '~/context/media'
import LetterReveal from '~/components/Effects/LetterReveal'
// import Bubble from '~/components/Intros/Bubble'
const Bubble = lazy(() => import('~/components/Intros/Bubble'))
import Grid from '~/components/Ui/Grid'

const Intro = ({ attributes: { line_01, line_02, caption, link, ...rest } }) => {
  const isMd = useMedia('md')

  return (
    <div className="Intro">
      <ClientOnly>
        <>
          <h1>
            <div className="line01">
              <Parallax offset={isMd ? 0 : -10}>
                <LetterReveal as="div" type="text-xxl" color="dark" stagger={0.02}>
                  {line_01}
                </LetterReveal>
              </Parallax>
            </div>
            <div className="line02">
              <Parallax offset={isMd ? 25 : 10}>
                <LetterReveal as="div" type="text-xxl" color="dark" stagger={0.02} delay={0.2}>
                  {line_02}
                </LetterReveal>
              </Parallax>
            </div>
          </h1>
        </>
      </ClientOnly>

      <div className="canvas">
        <ClientOnly>
          <Suspense>
            <Bubble />
          </Suspense>
        </ClientOnly>
      </div>

      <div className="footer">
        <Grid.Sizer size="10/14 3/14@md" className="caption">
          <Text as="div" className="description" type="text-s" color="dark-80">
            {caption}
          </Text>
        </Grid.Sizer>
      </div>

      <ContactButton />
    </div>
  )
}

Intro.defaultProps = {}
Intro.propTypes = {}

export default Intro

12480
