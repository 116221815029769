import { Link } from '@remix-run/react'
import Button from '~/components/Ui/Button'
import Spacer from '~/components/Ui/Spacer'
import Center from '~/components/Ui/Center'
import Text from '~/components/Ui/Text'
import Inner from '~/components/Ui/Inner'
import InView from '~/components/Effects/InView'
import Grid from '~/components/Ui/Grid'

const BoldText = ({ attributes: { title, text, link, spacers } }) => (
  <Inner className="BoldText">
    <Grid.Sizer size="10/14@md" push="2/14@md">
      <Spacer scale={spacers?.top} vertical />
      <Center>
        <InView>
          <Text as="h2" type="caption" center color="dark">
            {title}
          </Text>
        </InView>
        <Spacer scale="6 10@md" vertical />
        <InView>
          <Text as="div" type="text-l" center color="dark">
            {text}
          </Text>
        </InView>
        {link && (
          <>
            <Spacer scale="6 10@md" vertical />
            <InView>
              <Button as={Link} to={link?.url}>
                {link?.title}
              </Button>
            </InView>
          </>
        )}
      </Center>
      <Spacer scale={spacers?.bottom} vertical />
    </Grid.Sizer>
  </Inner>
)

BoldText.defaultProps = {}
BoldText.propTypes = {}

export default BoldText
