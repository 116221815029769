import classNames from 'classnames'

const generateSourceSet = (url, size, fallbackSize) => {
  if (Array.isArray(size)) {
    const urls = size.map(s => {
      const [subSize, modifier] = s.split(':')
      return `${url?.[subSize || fallbackSize]} ${modifier}`
    })
    return urls.join(', ')
  }
  return url?.[size || fallbackSize]
}

const Picture = ({ url, size, queries, title, alt, mime_type, cover, className }) => (
  <picture className="Picture">
    {queries.map(query => (
      <source
        key={query?.size + query?.query}
        srcSet={generateSourceSet(url, query?.size, 'original')}
        media={query?.query || undefined}
        type={query?.type || mime_type || undefined}
      />
    ))}
    <img
      srcSet={generateSourceSet(url, size)}
      alt={alt || undefined}
      title={title || undefined}
      className={classNames('Image', className, { cover })}
    />
  </picture>
)

Picture.defaultProps = {
  queries: [],
  url: [],
  size: 'original',
}
Picture.propTypes = {}

export default Picture
