import ClientOnly from '~/components/Helpers/ClientOnly'
import Spacer from '~/components/Ui/Spacer'
import ElementMarquee from '~/components/Effects/ElementMarquee'

const Logos = ({ attributes: { logos, reverse, spacers } }) => (
  <>
    <div className="Logos-Wrap">
      <Spacer scale={spacers?.top} vertical />
      <ClientOnly>
        <ElementMarquee className="Logos" direction={reverse ? 'right' : 'left'} speed={40}>
          {logos.map(logo => (
            <div key={logo?.id} className="logo">
              <img src={logo?.url?.original} alt={logo?.alt} title={logo?.title} />
            </div>
          ))}
        </ElementMarquee>
      </ClientOnly>
      <Spacer scale={spacers?.bottom} vertical />
    </div>
  </>
)

Logos.defaultProps = {}
Logos.propTypes = {}

export default Logos
