import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Inner from '~/components/Ui/Inner'
import InView from '~/components/Effects/InView'
import Spacer from '~/components/Ui/Spacer'

const TwoColumn = ({ attributes: { title, headline, text, spacers } }) => (
  <Inner className="TwoColumn">
    <Spacer scale={spacers?.top} vertical />
    <Grid.Row>
      <Grid.Column size="5/14@md" push="1/14@md">
        {title && (
          <>
            <InView>
              <Text as="div" type="caption" color="dark-80">
                {title}
              </Text>
            </InView>
            <Spacer scale="3" vertical />
          </>
        )}
        <InView>
          <Text as="h2" type="text-l" color="dark">
            {headline}
          </Text>
        </InView>
      </Grid.Column>
      <Grid.Column size="6/14@md" push="1/14@md">
        <Spacer scale="6 0@md" vertical />
        <InView>
          <Text as="div" type="text-m" color="dark" html nl2br>
            {text}
          </Text>
        </InView>
      </Grid.Column>
    </Grid.Row>
    <Spacer scale={spacers?.bottom} vertical />
  </Inner>
)

TwoColumn.defaultProps = {}
TwoColumn.propTypes = {}

export default TwoColumn
