import { motion } from 'framer-motion'
import Text from '~/components/Ui/Text'
import { rand } from '~/utils/helpers'

const LetterReveal = ({ children, random, stagger, delay, ...textProps }) => {
  const lines = children.replaceAll('<br />', '').split(/\r?\n/)
  const lineChars = []

  const variants = {
    hidden: { y: '100%' },
    visible: v => ({ y: 0, transition: { delay: v * stagger + delay } }),
  }

  lines.map(line => {
    const chars = line.split('')
    lineChars.push(chars)
  })

  return (
    <div className="LetterReveal">
      {lineChars.map((chars, idx) => (
        <div className="line" key={idx}>
          {chars.map((char, idxc) => (
            <motion.div
              key={idx + idxc}
              className="letter"
              variants={variants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 'some' }}
              custom={random ? rand(0, 10) : (idx + 1) * idxc}
            >
              <Text {...textProps} html>
                {char === ' ' ? '&nbsp;' : char}
              </Text>
            </motion.div>
          ))}
        </div>
      ))}
    </div>
  )
}

LetterReveal.defaultProps = {
  random: false,
  children: '',
  stagger: 1,
  delay: 0,
}
LetterReveal.propTypes = {}

export default LetterReveal
