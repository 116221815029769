import Text from '~/components/Ui/Text'

const Unknown = ({ component }) => (
  <div className="Unknown">
    <Text as="div" center type="text-l">
      Module Component "{component}" is missing.
    </Text>
  </div>
)

Unknown.defaultProps = {}
Unknown.propTypes = {}

export default Unknown
