import { Link } from '@remix-run/react'
import SectionHead from '~/components/Atoms/SectionHead'
import Spacer from '~/components/Ui/Spacer'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Icon from '~/components/Ui/Icon'
import InView from '~/components/Effects/InView'
import TextHover from '~/components/Effects/TextHover'
import Inner from '~/components/Ui/Inner'
import { useMedia } from '~/context/media'

const Jobs = ({ attributes: { jobslist, section_head, spacers } }) => {
  const mobile = useMedia('xs')

  return (
    <Inner className="Jobs">
      <Grid.Sizer size="12/14@md" push="1/14@md">
        <Spacer scale={spacers?.top} vertical />
        <SectionHead title={section_head?.title} description={section_head?.description} />
        <Spacer scale="6 10@md" vertical />
        <ul>
          {jobslist.map(job => (
            <li key={job.id}>
              <InView>
                <Text
                  as={Link}
                  to={job.url.relative}
                  className="link"
                  type="text-ll"
                  color={mobile ? 'brand' : 'dark'}
                >
                  <h3>
                    <TextHover
                      from={{
                        fontWeight: 600,
                        color: 'var(--color-textDark)',
                        transition: { color: { duration: 0.05 } },
                      }}
                      to={{
                        fontWeight: 700,
                        color: 'var(--color-textBrand)',
                        transition: { color: { duration: 0.05 } },
                      }}
                    >
                      <span>{job.title}</span>
                      <Icon type="arrow-right" color="inherit" />
                    </TextHover>
                  </h3>
                </Text>

                <div>
                  <ul className="tags">
                    {job?.tags.map(tag => (
                      <li key={tag}>
                        <Text as="span" className="tag" type="text-xs" color="white">
                          {tag}
                        </Text>
                      </li>
                    ))}
                  </ul>
                </div>

                <Spacer scale="6" vertical />
              </InView>
            </li>
          ))}
        </ul>
        <Spacer scale={spacers?.bottom} vertical />
      </Grid.Sizer>
    </Inner>
  )
}

Jobs.defaultProps = {
  attributes: { jobslist: [] },
}
Jobs.propTypes = {}

export default Jobs
