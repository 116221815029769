import classNames from 'classnames'
import Text from '~/components/Ui/Text'
import Grid from '~/components/Ui/Grid'
import Spacer from '~/components/Ui/Spacer'
import Inner from '~/components/Ui/Inner'
import InView from '~/components/Effects/InView'
import LetterReveal from '~/components/Effects/LetterReveal'
import { useSite, useStrings } from '~/context/site'
import { useSiteState } from '~/context/state'
import tracker from '~/services/tracker'

const ContactHead = ({ attributes: { headline } }) => {
  const { setCursor } = useSiteState()
  const { contact } = useSite('settings')
  const strings = useStrings()

  console.log(strings, strings['contact.write_us'])

  return (
    <Inner className={classNames('ContactHead')}>
      <div className="content">
        <h1>
          <LetterReveal
            random={false}
            type="text-xxl"
            color="white"
            className="headline"
            stagger={0.02}
          >
            {headline}
          </LetterReveal>
        </h1>

        <Spacer scale="20@md" vertical />

        <Grid.Row className="row">
          <Grid.Column size="4/14@md" className="column">
            <div>
              <Text as="div" color="brand" type="caption">
                {strings['contact.write_us']}
              </Text>

              <InView>
                <Text
                  as="a"
                  href={`mailto:${contact?.email}`}
                  target="_blank"
                  type="text-m"
                  color="white"
                  onClick={() => tracker.event('lead', 'click.mail', 'contact')}
                  onMouseEnter={() => setCursor('mail')}
                  onMouseLeave={() => setCursor()}
                >
                  {contact?.email}
                </Text>
              </InView>
            </div>
          </Grid.Column>
          <Grid.Column size="4/14@md" push="1/14@md" className="column">
            <div>
              <Text as="div" color="brand" type="caption">
                {strings['contact.call_us']}
              </Text>

              <InView>
                <Text
                  as="a"
                  href={`tel:${contact?.phoneRaw}`}
                  target="_blank"
                  type="text-m"
                  color="white"
                  onClick={() => tracker.event('lead', 'click.phone', 'contact')}
                  onMouseEnter={() => setCursor('phone')}
                  onMouseLeave={() => setCursor()}
                >
                  {contact?.phone}
                </Text>
              </InView>
            </div>
          </Grid.Column>
          <Grid.Column size="4/14@md" push="1/14@md" className="column">
            <div>
              <Text as="div" color="brand" type="caption">
                {strings['contact.visit_us']}
              </Text>

              <InView>
                <Text
                  as="a"
                  href={contact?.maps}
                  target="_blank"
                  type="text-m"
                  color="white"
                  onClick={() => tracker.event('lead', 'click.maps', 'contact')}
                  onMouseEnter={() => setCursor('location')}
                  onMouseLeave={() => setCursor()}
                >
                  {contact?.street}, <br />
                  {contact?.zip} {contact?.city}
                </Text>
              </InView>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Spacer scale="20@md" vertical />
      </div>
    </Inner>
  )
}

ContactHead.defaultProps = {}
ContactHead.propTypes = {}

export default ContactHead
